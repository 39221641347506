import { Dispatch } from 'redux'
import Cookies from "js-cookie"
import { history } from '../../router'
import { store } from '../../store'
import { formatAccountDto } from '../../utils'
import { AuthService } from '../../services/AuthService'
import { AccountService } from '../../services/AccountService'
import { FacebookService } from '../../services/FacebookService'
import * as types from './types'
import { RESET_CAMPAIGN_FILTER_STATE } from "../Campaigns/Filter/types"


export const resetLoading = () => {
	return { type: types.RESET_LOADING_ACCOUNT }
}

export const resetStatus = () => ({ type: types.RESET_ACCOUNT_STATUS })

export const validateInvite =
	(invite: string, accountId: string) => async (dispach: Dispatch<types.DispatchTypes>) => {
		try {
			dispach({ type: types.VALIDATE_INVITE_REQUEST });

			const service = new AuthService();
			const res = await service.getUserDataByInvite(invite, accountId);

			dispach({
				type: types.VALIDATE_INVITE_SUCCESS,
				payload: res,
			});

		} catch (error) {

			const res = JSON.parse((await (error as any).json()).message);
			history.push('/invalid-invite');

			dispach({
				type: types.VALIDATE_INVITE_FAILED,
				payload: res,
			});

		}
	}

export const signUpAccount =
	(form: any) => async (dispatch: Dispatch<types.DispatchTypes>) => {
		try {
			dispatch({ type: types.SIGN_UP_ACCOUNT_REQUEST })

			const service = new AuthService()
			const res = await service.accountSignUp(form)

			dispatch({
				type: types.SIGN_UP_ACCOUNT_SUCCESS,
				payload: res,
			});

			history.push("/verify-account");

		} catch (error) {
			const { errors, message } = error as any;
			if (message) {
				return dispatch({
					type: types.SIGN_UP_ACCOUNT_FAILED,
					payload: (error as any).message,
				})
			}

			if (errors) {
				const fields = Object.keys((error as any).errors);
				let errorstr = "";

				fields.forEach((field) => {
					errorstr = `${field}: ${(error as any).errors[field].join(" ")}. `;
				});

				return dispatch({
					type: types.SIGN_UP_ACCOUNT_FAILED,
					payload: errorstr
				});
			}

			return dispatch({
				type: types.SIGN_UP_ACCOUNT_FAILED,
				payload: 'Something went wrong',
			})
		}
	}

export const confirmAccount =
	(id: string, token: string) =>
		async (dispatch: Dispatch<types.DispatchTypes>) => {
			try {
				dispatch({ type: types.CONFIRM_ACCOUNT_REQUEST })

				const service = new AuthService()
				const res = await service.confirmAccount(id, token)

				if (res === 200) {
					dispatch({
						type: types.CONFIRM_ACCOUNT_SUCCESS,
					})
				}

			} catch (error) {
				dispatch({
					type: types.CONFIRM_ACCOUNT_FAILED,
					payload: (error as any).message,
				})
			}
		}

export const signInAccount =
	(form: any) => async (dispatch: Dispatch<types.DispatchTypes>) => {
		const { userName, password } = form
		try {
			dispatch({ type: types.SIGN_IN_ACCOUNT_REQUEST })

			const service = new AuthService();
			const res = await service.accountSignIn({ userName, password });

			dispatch({
				type: types.SIGN_IN_ACCOUNT_SUCCESS,
				payload: res,
			});

		} catch (error) {
			if (typeof error === 'object' && (error as any).message) {
				switch ((error as any).message) {
					case 'The account was not verified':
						return dispatch({
							type: types.SIGN_IN_ACCOUNT_NOT_VERIFIED,
							payload: (error as any).message
						})

					case 'Invalid user or password':
						return dispatch({
							type: types.SIGN_UP_ACCOUNT_FAILED,
							payload: (error as any).message
						});

					default:
						return dispatch({
							type: types.SIGN_IN_ACCOUNT_FAILED,
							payload: (error as any).message
						})
				}
			}
		}
	}

export const impersonateAccount =
	(impersonationToken: string) => async (dispatch: Dispatch<types.DispatchTypes>) => {
		try {
			dispatch({ type: types.SIGN_IN_ACCOUNT_REQUEST })

			const service = new AuthService();
			const res = await service.accountImpersonatedSignIn(impersonationToken);

			dispatch({
				type: types.SIGN_IN_ACCOUNT_SUCCESS,
				payload: res,
			});

			dispatch({
				type: types.SIGN_IN_IMPERSONATED_ACCOUNT,
				payload: res,
			})

		} catch (error) {
			if (typeof error === 'object' && (error as any).message) {
				switch ((error as any).message) {
					case 'The account was not verified':
						return dispatch({
							type: types.SIGN_IN_ACCOUNT_NOT_VERIFIED,
							payload: (error as any).message
						})

					case 'Invalid user or password':
						return dispatch({
							type: types.SIGN_UP_ACCOUNT_FAILED,
							payload: (error as any).message
						});

					default:
						return dispatch({
							type: types.SIGN_IN_ACCOUNT_FAILED,
							payload: (error as any).message
						})
				}
			}
		}
	}

export const resendVerificationEmail =
	(email: any) => async (dispatch: Dispatch<types.DispatchTypes>) => {
		try {
			dispatch({ type: types.RESEND_VERIFICATION_EMAIL_REQUEST })

			const service = new AuthService()
			const res = await service.resendVerificationEmail(email)

			if (res === 200) {
				dispatch({ type: types.RESEND_VERIFICATION_EMAIL_SUCCESS })
			} else {
				dispatch({
					type: types.RESEND_VERIFICATION_EMAIL_FAILED,
					payload: res.message,
				})
			}
		} catch (error) {
			console.log(error)
			dispatch({
				type: types.RESEND_VERIFICATION_EMAIL_FAILED,
				payload: (error as any).message,
			})
		}
	}

export const forgotPassword =
	(email: string) => async (dispatch: Dispatch<types.DispatchTypes>) => {
		try {
			dispatch({
				type: types.FORGOT_PASSWORD_REQUEST,
			})

			const service = new AuthService()
			const res = await service.forgotPassword(email)

			if (res === 200) {
				dispatch({
					type: types.FORGOT_PASSWORD_SUCCESS,
				})
			}
		} catch (error) {
			console.log(error)
			dispatch({
				type: types.FORGOT_PASSWORD_FAILED,
				payload: (error as any).message,
			})
		}
	}

export const resetPassword =
	(id: string, password: string, token: string) =>
		async (dispatch: Dispatch<types.DispatchTypes>) => {
			try {
				dispatch({
					type: types.RESET_PASSWORD_REQUEST,
				})

				const service = new AuthService()
				const res = await service.resetPassword(id, password, token)

				dispatch({
					type: types.RESET_PASSWORD_SUCCESS,
					payload: res,
				});
			} catch (error) {
				console.log(error)
				dispatch({
					type: types.RESET_PASSWORD_FAILED,
					payload: (error as any).message,
				})
			}
		}

export const updateAccountPassword: any =
	(form: any, id: string) =>
		async (dispatch: Dispatch<types.DispatchTypes>) => {
			try {
				dispatch({
					type: types.UPDATE_ACCOUNT_PASSWORD_REQUEST,
				})
				// await verifyToken();

				const service = new AccountService()
				const res = await service.changePassword(form, id)

				console.log(res)
				dispatch({
					type: types.UPDATE_ACCOUNT_PASSWORD_SUCCESS,
				})
			} catch (error) {
				dispatch({
					type: types.UPDATE_ACCOUNT_PASSWORD_FAILED,
					payload: (error as any).message,
				})
			}
		}

export const getAccountById =
	(id: string) => async (dispatch: Dispatch<any>) => {
		try {
			dispatch({
				type: types.GET_ACCOUNT_BY_ID_REQUEST,
			})

			const accountService = new AccountService();
			const res = await accountService.getAccountById(id);

			if (res.signupCode) {
				localStorage.setItem("SIGNUP_CODE", res.signupCode);
			}

			dispatch({
				type: types.GET_ACCOUNT_BY_ID_SUCCESS,
				payload: formatAccountDto(res),
			})

		} catch (error) {
			console.log(error);
			dispatch({
				type: types.GET_ACCOUNT_BY_ID_FAILED,
				payload: (error as any).message,
			});
			return;
		}
	}

export const getRecommendedSteps: any =
	(accountId: string) => async (dispatch: Dispatch<types.DispatchTypes>) => {
		try {
			dispatch({ type: types.ACCOUNT_RECOMMENDED_STEPS_REQUEST })

			const service = new AccountService()
			const res = await service.getRecommendedSteps(accountId)

			dispatch({
				type: types.ACCOUNT_RECOMMENDED_STEPS_SUCCESS,
				payload: res,
			})
		} catch (error) {
			dispatch({
				type: types.ACCOUNT_RECOMMENDED_STEPS_FAILED,
				payload: JSON.stringify(error),
			})
		}
	}

export const skipRecommendedStep: any =
	(accountId: string, stepId: string) =>
		async (dispatch: Dispatch<types.DispatchTypes>) => {
			try {
				dispatch({ type: types.SKIP_RECOMMENDED_STEP_REQUEST })

				const api = new AccountService()
				const res = await api.skipRecommendedStep(accountId, stepId)

				if (res === 200) {
					dispatch({
						type: types.SKIP_RECOMMENDED_STEP_SUCCESS,
						payload: stepId,
					})
				}
			} catch (error) {
				dispatch({
					type: types.SKIP_RECOMMENDED_STEP_FAILED,
					payload: JSON.stringify(error),
				})
			}
		}

export const updateAccount: any =
	(form: any) => async (dispatch: Dispatch<types.DispatchTypes>) => {
		try {
			dispatch({
				type: types.UPDATE_ACCOUNT_REQUEST,
			})
			// await verifyToken();
			const service = new AccountService()
			const res = await service.updateAccount(form)
			const {
				account: { details },
			} = store.getState()


			if (res === 200) {
				dispatch({
					type: types.UPDATE_ACCOUNT_SUCCESS,
					payload: form
				})
			} else {
				let messaje: string = ''
				Object.entries(res.errors).forEach(([key, value]) => {
					messaje = key + ': ' + value
				})
				dispatch({
					type: types.UPDATE_ACCOUNT_FAILED,
					payload: messaje
				})
			}

			if (details.email !== form.email) {
				dispatch({
					type: types.EMAIL_CHANGE_TOGGLE,
					payload: true,
				})
			}
		} catch (error) {
			dispatch({
				type: types.UPDATE_ACCOUNT_FAILED,
				payload: JSON.stringify(error),
			})
		}
	}

export const signOutAccount: any = (reload: boolean = false) =>
	async (dispatch: Dispatch<any>) => {

		const service = new AuthService();
		await service.accountSignOut();
		//await persistor.purge();

		dispatch({ type: RESET_CAMPAIGN_FILTER_STATE });
		dispatch({ type: 'RESET_FORM' });
		dispatch({ type: types.SIGN_OUT_ACCOUNT });


		localStorage.removeItem("SIGNUP_CODE");

		history.push('/');
		//await persistor.flush();
		//persistor.pause();

		if (reload) {
			//window.location.reload();
		}

		return;
	}

export const resetAccountState: any = () => {
	return { type: types.RESET_ACCOUNT_STATE }
}

export const signInResetInvalidAttempts: any = () => {
	return { type: types.SIGN_IN_RESET_INVALID_ATTEMPTS }
}

export const postFbAccessToken: any =
	(accountId: string, fbResponse: any) =>
		async (dispatch: Dispatch<types.DispatchTypes>) => {
			try {
				dispatch({ type: types.POST_FB_ACCESS_TOKEN_REQUEST })
				// await verifyToken();
				const { accessToken, name } = fbResponse
				const facebook = new FacebookService(accountId)
				const res = await facebook.postAccessToken(accessToken, name)
				if (res) {
					dispatch({
						type: types.POST_FB_ACCESS_TOKEN_SUCCESS,
						payload: res,
					})
				}
			} catch (error) {
				dispatch({
					type: types.POST_FB_ACCESS_TOKEN_FAILED,
					payload: JSON.stringify(error),
				})
			}
		}

export const validateFbIntegration: any =
	(accountId: string, fbResponse: any) =>
		async (dispatch: Dispatch<types.DispatchTypes>) => {
			try {

				dispatch({ type: types.VALIDATE_FB_INTEGRATION_REQUEST });

				const { accessToken, name } = fbResponse;
				const facebook = new FacebookService(accountId);
				const res = await facebook.validateIntegration(accessToken, name);

				dispatch({
					type: types.VALIDATE_FB_INTEGRATION_SUCCESS,
					payload: res,
				});

			} catch (error) {

				dispatch({
					type: types.VALIDATE_FB_INTEGRATION_FAILED,
					payload: error as string,
				});

			} finally {

				let nextUrl = "/app/settings/integrations";
				const touchpointId = Cookies.get("touchpointId");

				if (touchpointId) {
					nextUrl = `/app/set-touchpoint?form=Facebook&target=${touchpointId}`;
					Cookies.remove("touchpointId");
				}

				history.push(nextUrl);

			}
		}

export const updateAvatar = (accountId: string, avatar: any) => async (dispatch: Dispatch<types.DispatchTypes>) => {
	try {
		dispatch({ type: types.UPDATE_ACCOUNT_AVATAR_REQUEST });

		const service = new AccountService();
		const url = await service.uploadAvatar(accountId, avatar);
		const timestamp = '?t=' + new Date().getTime(); //create timestamp

		dispatch({
			type: types.UPDATE_ACCOUNT_AVATAR_SUCCESS,
			payload: url + timestamp
		});

	} catch (error) {
		dispatch({
			type: types.UPDATE_ACCOUNT_AVATAR_FAILED,
			payload: error
		})
	}
}