import React, { FC, useState, useEffect, createContext } from 'react';
import withWidth, { WithWidthProps } from '@material-ui/core/withWidth';


type SidebarContextModel = {
	open: boolean,
	toggleOpen: Function,
	setOpen: Function,
}

interface ProviderProps extends WithWidthProps {
	children: React.ReactNode,
}

export const SidebarContext = createContext<SidebarContextModel>({
	open: false,
	toggleOpen: () => console.warn('Sidebar Provider not working.'),
	setOpen: (value: boolean) => console.warn('Sidebar Provider not working', value),
});

const SidebarContextProvider: FC<ProviderProps> = ({ children, width }) => {
	const [open, setOpen] = useState(false);

	const toggleOpen = () => setOpen(!open);

	const value = { open, setOpen, toggleOpen };

	useEffect(() => {
		if (!width) return;

		if (["md", "lg", "xl"].includes(width)) setOpen(true)
		else setOpen(false);

	}, [width])

	return (
		<SidebarContext.Provider value={value}>
			{children}
		</SidebarContext.Provider>
	)
}

export default withWidth()(SidebarContextProvider); 